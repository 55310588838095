import { EmailValidator } from '@angular/forms';

export class Contact {

  constructor(
    public requestType: string,
    public firstName: string,
    public lastName: string,
    public clientEmail: string,
    public phoneNumber: number,
    public message: string
  ) { }
}
