import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Sms } from '../../models/sms';
import { environment } from '../../../environments/environment';

export interface FormModel {
  captcha?: string;
}

@Component({
  selector: 'app-locationlinkform',
  templateUrl: './locationlinkform.component.html',
  styleUrls: ['./locationlinkform.component.css']
})
export class LocationlinkformComponent implements OnInit {

  public formModel: FormModel = {};

  model = new Sms(null);
  submitted = false;
  // set as true so send error doesn't flash first
  sendstatus;

  constructor(private httpClient: HttpClient) { }

  onSubmit() { this.submitted = true; }

  newSmsMessage() {
    let messageBody =
      `Thank you for requesting our location link that will give you driving directions directly to ${environment.companyName}.
${environment.companyLocationLink}`;

    this.submitted = true;
    this.sendstatus = "sending";

    this.httpClient.post(environment.sendSmsApi,
      {
        "MobilePhone": this.model.phoneNumber,
        "Message": messageBody
        //"OriginUrl": window.location.hostname
      })
      .subscribe(
        data => {
          console.log("POST is successful ", data);
          this.sendstatus = "success";
        },
        error => {
          console.log("There was an error sending the message.", error);
          this.sendstatus = "fail";
        }
      );
  }

  startOver() {
    //this.model.phoneNumber = "";
    this.submitted = false;
  }

  showFormControls(form: any) {
    return form && form.controls['name'] &&
      form.controls['name'].value; // Dr. IQ
  }

  ngOnInit() {

  }

}
