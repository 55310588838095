import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ProductsComponent } from './products/products.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { TaptocontactComponent } from './components/taptocontact/taptocontact.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { EmailformComponent } from './components/emailform/emailform.component';
import { LocationlinkformComponent } from './components/locationlinkform/locationlinkform.component';
import { FullslateComponent } from './components/fullslate/fullslate.component';
import { SmsformComponent } from './components/smsform/smsform.component';
import { AppointmentstickyComponent } from './components/appointmentsticky/appointmentsticky.component';
import { FreeconsultComponent } from './components/freeconsult/freeconsult.component';

const cookieConfig: NgcCookieConsentConfig = {
  "cookie": {
    //"domain": "a7prodstorage.z5.web.core.windows.net"
    "domain": "templeac.com"
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#4caf50",
      "text": "#000000",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "We use cookies to make our site work better for you. ",
    "dismiss": "Got it!",
    "deny": "Refuse cookies",
    "link": "Learn more",
    "href": "/privacy",
    "policy": "Cookie Policy"
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    AboutComponent,
    PrivacyComponent,
    ProductsComponent,
    ServicesComponent,
    ContactComponent,
    FooterComponent,
    TaptocontactComponent,
    EmailformComponent,
    LocationlinkformComponent,
    FullslateComponent,
    SmsformComponent,
    AppointmentstickyComponent,
    FreeconsultComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TabsModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
