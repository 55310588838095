import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Contact } from '../../models/contact';
import { environment } from '../../../environments/environment';

export interface FormModel {
  captcha?: string;
}

@Component({
  selector: 'app-emailform',
  templateUrl: './emailform.component.html',
  styleUrls: ['./emailform.component.css']
})
export class EmailformComponent implements OnInit {
  public formModel: FormModel = {};

  eRequestTypes = ['My appointment', 'Website', 'Products', 'Services', 'Other'];
  model = new Contact('', '', '', '', null, '');
  submitted = false;
  // set as true so send error doesn't flash first
  sendstatus;

  constructor(private httpClient: HttpClient) { }

  onSubmit() { this.submitted = true; }

  newContactMessage() {
    let messageSubject = `[WEBSITE CONTACT REQUEST] - ${this.model.requestType} - From ${this.model.firstName} ${this.model.lastName}`
    let messageBody = `This is a new contact request from ${environment.companyName}.<br>
                Sent from: ${window.location.href}<br>
                First Name: ${this.model.firstName}<br>
                Last Name: ${this.model.lastName}<br>
                Email: ${this.model.clientEmail}<br>
                Type of Request: ${this.model.requestType}<br>
                Message Below
                <hr><br>
                <pre>${this.model.message.replace(/<\/?[^>]+(>|$)/g, "")} </pre>`;
                //cleanText = strInputCode.replace(/<\/?[^>]+(>|$)/g, "");

    //console.log("Sending from: ", window.location.hostname);
    this.submitted = true;
    this.sendstatus = "sending";

    this.httpClient.post(environment.sendMailApi,
      {
        "FirstName": this.model.firstName,
        "LastName": this.model.lastName,
        "RecipientEmail": environment.companyEmail,
        "ClientEmail": this.model.clientEmail,
        "Bcc": environment.techContact,
        "Subject": messageSubject,
        "Message": messageBody,
        "OriginUrl": window.location.hostname
      })
      .subscribe(
        data => {
          console.log("POST is successful ", data);
          this.sendstatus = "success";
        },
        error => {
          console.log("There was an error sending the message.", error);
          this.sendstatus = "fail";
        }
      );
  }

  startOver() {
    this.submitted = false;
  }

  showFormControls(form: any) {
    return form && form.controls['name'] &&
      form.controls['name'].value; // Dr. IQ
  }

  ngOnInit() {

  }
}
