// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  companyName: 'Temple Acupuncture',
  companyEmail: 'don@templeac.com',
  companyAddress: '115-4th Ave South - Three Hills, AB, T0M 2A0',
  companyPhoneDisplay: '(403)-425-0770',
  companyPhoneDial: '+1-403-425-0770',
  companyLocationLink: 'http://maps.apple.com/?daddr=115-4th+Ave+S,Three+Hills+Alberta+Canada',
  sendMailApi: 'http://localhost:7071/api/sendgridmail',
  sendSmsApi: 'http://localhost:7071/api/sendsms',
  techContact: 'yeelut@hotmail.com',
    siteVersion: '*191215.1'
};
