import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Contact } from '../../models/contact';
import { environment } from '../../../environments/environment';

export interface FormModel {
  captcha?: string;
}

@Component({
  selector: 'app-smsform',
  templateUrl: './smsform.component.html',
  styleUrls: ['./smsform.component.css']
})
export class SmsformComponent implements OnInit {

  public formModel: FormModel = {};

  eRequestTypes = ['My appointment', 'Website', 'Products', 'Services', 'Other'];
  model = new Contact('', '', '', '', null, '');
  submitted = false;
  //smsForm = true;
  // Set as true so send error doesn't flash first
  sendstatus;

  constructor(private httpClient: HttpClient) { }

  onSubmit() { this.submitted = true; }

  newSmsMessage() {
    //let messageSubject = `[WEBSITE CONTACT REQUEST] - ${this.model.requestType} - From ${this.model.firstName} ${this.model.lastName}`
    let messageBody = `This is a new contact request from the ${environment.companyName} website.
Sent from: ${window.location.href}
First Name: ${this.model.firstName}
Last Name: ${this.model.lastName}
Phone Number: ${this.model.phoneNumber}
Type of Request: ${this.model.requestType}

Message Below
---------------
${this.model.message.replace(/<\/?[^>]+(>|$)/g, "")}
---------------`;
    //cleanText = strInputCode.replace(/<\/?[^>]+(>|$)/g, "");

    //console.log("Sending from: ", window.location.hostname);
    this.submitted = true;
    this.sendstatus = "sending";

    this.httpClient.post(environment.sendSmsApi,
      {
          "MobilePhone": environment.companyPhoneDial,
        "Message": messageBody
      })
      .subscribe(
        data => {
          console.log("POST is successful ", data);
          this.sendstatus = "success";
        },
        error => {
          console.log("There was an error sending the message.", error);
          this.sendstatus = "fail";
        }
      );
  }

  startOver() {
    this.submitted = false;
  }

  showFormControls(form: any) {
    return form && form.controls['name'] &&
      form.controls['name'].value; // Dr. IQ
  }

  ngOnInit() {

  }
}
