import { Component, OnInit, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap';
import { ActivatedRoute, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  @ViewChild('staticTabs', { static: false })
  staticTabs: TabsetComponent;
  //private route = ActivatedRoute;

  selectTab(tabId) {

    if (tabId) {
      if (tabId < 3) {
        this.staticTabs.tabs[tabId].active = true;
        //console.log(tabId)
      }
    }
  }

  constructor(private activatedRoute: ActivatedRoute) { }

  ngAfterViewInit() {

    // Scroll to top of page first
    window.scroll(0, 0);

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.tab) {
        this.selectTab(+params.tab)

        //console.log(params.tab);

        // Clears tab in address but where is "router"?
        //this.route.navigate([], { queryParams: { tab: null, }, queryParamsHandling: 'merge', });
      }
    });
  }

  ngOnInit() { }

}
